
import AuthMixin from '@/mixins/AuthMixin';
import LoginTabs from '@/components/Auth/Tabs.vue';
import VerifyScreen from '@/components/Auth/Create/VerifyScreen.vue';
import CompanyInfoBody from '@/components/Auth/Create/Modals/CompanyInfoBody.vue';
import { handleErrorUI, handleSuccessUI } from '@/util/error';

export default {
  components: {
    LoginTabs,
    CompanyInfoBody,
    VerifyScreen
  },
  mixins: [AuthMixin],
  data() {
    return {
      step: 0,
      company: {},
      email: '',
      domain: '',
      requestedUser: false,
      loading: false
    };
  },
  methods: {
    handleVerification(res) {
      /* if (!res.tenant) {
        handleErrorUI(new Error(this.$t('tenant_not_exists')));
        return;
      } */

      this.step = 1;
      this.email = res.email;
      this.domain = res.domain;
      this.requestedUser = res.requestedUser;

      this.company = {
        name: res.tenant.companyName,
        industryName: res.tenant.industry.name,
        contacts: [
          {
            type: '',
            email: '',
            title: '',
            mobile: '',
            phone: res.tenant.phone
          }
        ]
      };
    },
    async sendRequest() {
      this.loading = true;
      try {
        await this.$repo.auth.requestJoinToAdmin(
          this.domain,
          this.email,
          this.requestedUser
        );
        handleSuccessUI(this.$t('success_message'));
        this.$router.push({ name: 'auth:login' }).catch(_ => {});
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      } // finally
    }
  }
};
